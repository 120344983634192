import type React from "react"
import { FloatButton } from "antd"
import styled, { keyframes } from "styled-components"
import lineImage from "assets/line.png";
import { useEffect, useState } from "react";

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const FloatingLink = styled.a<{ $isVisible: boolean }>`
  position: fixed;
  top: 19%;

  @media (min-width: 768px) {
    top: 43%;
  }

  right: -5px;
  z-index: 3000;
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease;
  animation: ${slideInFromRight} 0.8s ease-out;
  transform: translateX(${(props) => (props.$isVisible ? "0" : "100%")});

  &:hover {
    transform: translateX(0) scale(1.05);
  }
`


const ContactImage = styled.img`
  width: 120px;
  @media (min-width: 768px) {
    width: 150px;
  }
  height: 100%;
  object-fit: contain;
  padding: 5px; // Added padding to prevent image from touching edges
`


export const FloatingContactButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 800)
    return () => clearTimeout(timer)
  }, [])
  const handleClick = () => {
    window.open('https://lin.ee/yHJ0LF9', "_blank", "noopener,noreferrer")
  }

  return (
    <FloatingLink href="https://lin.ee/yHJ0LF9" target="_blank" rel="noopener noreferrer" $isVisible={isVisible}>
      <ContactImage src={lineImage} alt="Contact us on LINE" />
    </FloatingLink>
  )
}


