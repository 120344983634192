import type React from "react"
import styled, { keyframes, css } from "styled-components"
import activityImag from "assets/activity.png";
import { useEffect, useState } from "react";
import {VerticalRightOutlined, CrownOutlined} from'@ant-design/icons';
const slideInFromRight = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`
const slideOutToRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`

const DivContent = styled.div`
  position: fixed;
  top: 14%;

  @media (min-width: 768px) {
    top: 33%;
  }

  left: -5px;
  z-index: 3000;
  display: block;
`

const FloatingActivity = styled.a<{ $isVisible: boolean, $isExpanded: boolean }>`
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: translateX(${(props) => (!props.$isExpanded ? "-100%" : "0")});

  &:hover {
    transform: translateX(0) scale(1.05);
  }
`


const ContactImage = styled.img`
  width: 120px;
  @media (min-width: 768px) {
    width: 180px;
  }
  height: 100%;
  object-fit: contain;
  padding: 5px; // Added padding to prevent image from touching edges
`
const ToggleButton = styled.button`
  width: 36px;
  height: 30px;
  background-color: #e1dc0036;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0;
`


export const ActivityButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(true)
      setIsExpanded(true)
    }, 800)
    return () => clearTimeout(timer)
  }, [])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <DivContent>
      <ToggleButton onClick={toggleExpand}>{isExpanded ? <VerticalRightOutlined /> : <CrownOutlined />}</ToggleButton>
      <FloatingActivity href="https://yolo789login.com/pages/aaio/#/main/event/list" target="_blank" rel="noopener noreferrer" $isVisible={isVisible}  $isExpanded={isExpanded}>
        <ContactImage src={activityImag} alt="Contact us on LINE" />
      </FloatingActivity>
    </DivContent>
  )
}


